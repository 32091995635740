import React from "react"
import { graphql, navigate } from "gatsby"
import parse from "html-react-parser"

export default function NoticeSingle({ data }) {
  const post = data.post

  return (
    <div>
      <div className="py-16">
        <div className="w-full lg:max-w-screen-lg mx-auto px-4 lg:px-8">
          <div className="mb-8">
            <h2 className="text-3xl font-medium text-gray-900 mb-3">
              {post.title}
            </h2>
            <p className="text-gray-500">{post.date}</p>
          </div>
          <div className="border-t border-b py-8">
            <div className="max-w-screen-lg prose lg:prose-lg">
              {parse(post.content)}
            </div>
          </div>
          <div className="mt-8 text-center">
            <button
              className="border border-gray-300 px-5 py-3 rounded-full"
              onClick={() => navigate(-1)}
            >
              목록
            </button>
          </div>
        </div>
      </div>
    </div>
  )
}

export const pageQuery = graphql`
  query NoticeById($id: String!, $previousPostId: String, $nextPostId: String) {
    post: wpNotice(id: { eq: $id }) {
      id
      title
      content
      excerpt
      date(formatString: "yyyy년 MM월 DD일", locale: "ko")
    }
    previous: wpCase(id: { eq: $previousPostId }) {
      uri
      title
    }
    next: wpCase(id: { eq: $nextPostId }) {
      uri
      title
    }
  }
`
